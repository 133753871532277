import * as React from "react";
import { Link } from "gatsby";
import ModelViewer from "../components/modelviewer/modelviewer";

// markup
const NotFoundPage = () => {
  return (
    <main className="m-auto flex flex-col text-white">
      <h1 className="text-2xl font-bold pb-2">Page not found</h1>
      <div className="model-viewer hidden pointer-events-none md:block lg:pointer-events-auto">
        <ModelViewer
          scale="40"
          modelPath={"./assets/donut.gltf"}
          position={[800, -200, 850]}
          // position={[75, -290, -250]}
        />
      </div>
      <p className="hidden md:block">
        Sorry, all we could find was this donut.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
      </p>
      <p>
        <Link to="/">Go home</Link>.
      </p>
    </main>
  );
};

export default NotFoundPage;
